@import "../../assets/scss/theme.scss";

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
}

.active-premiums {
  color: $primary-dark;
  margin: auto;
  font-weight: 500;
}

.surplus {
  color: $primary-light;
  margin: auto;
  font-weight: 500;
}

.hallmark {
  color: $pink;
  margin: auto;
  font-weight: 500;
}

.recharts-label {
  font-weight: 700 !important;
}

.premium {
  color: $green;
  margin: auto;
  font-weight: 500;
}

.payout {
  color: $pink;
  font-weight: 500;
  margin: auto;
}

.positive-total {
  color: $primary-light;
  font-weight: 500;
}

.negative-total {
  color: $primary-dark;
  font-weight: 500;
}
