@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.align-end {
  justify-content: end !important;
  --bs-text-opacity: 1;
  margin-top: 0.25rem;
  font-size: 87.5%;
  text-align: end;
}

.btn-text {
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 0.5rem !important;
  cursor: pointer;
}

.without-padding {
  padding-left: 0 !important;
}

//

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}

#custom-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 400px;
  @include respond-to(sm) {
    width: 300px;
  }
}

#custom-prompt label {
  display: block;
  margin-bottom: 10px;
}

#custom-prompt input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.buttons-container button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#cancel-button {
  color: black;
  margin-right: 10px;
  cursor: pointer;
}

#confirm-button {
  color: white;
}

#error-message {
  color: #f44336;
  margin-top: 10px;
  display: none;
}

.buttons {
  text-align: end;
}

.read-only {
  color: rgb(255, 115, 0);
  font-size: 0.75rem;
  text-align: center;
}

.white-text {
  color: white;
}

.fade {
  z-index: 1 !important;
}

.modal-title-centered {
  text-align: center !important;
  width: 100%;
}
