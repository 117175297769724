@import "../../assets/scss/theme.scss";

.right-aligned {
  text-align: end !important;
  width: 90% !important;
}

.weight-600 {
  font-weight: 600 !important;
}

.metric-card {
  padding: 15px !important;
  color: $primary-dark;
}

.metric-value {
  font-size: 1.5rem !important;
  font-weight: 400;
}

.with-pointer {
  cursor: pointer;
}
