$xl: 1799px;
$lg: 1399px;
$md: 1023px;
$sm: 767px;
$xs: 479px;

@mixin respond-to($media) {
  @if $media == xl {
    @media only screen and (max-width: $xl) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (max-width: $lg) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (max-width: $md) {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (max-width: $sm) {
      @content;
    }
  } @else if $media == xs {
    @media only screen and (max-width: $xs) {
      @content;
    }
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}
