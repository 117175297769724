@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.etk-card-detail-inside {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;

  span {
    font-weight: 600;
  }

  padding-left: 60px !important;
  padding-right: 10px !important;

  @include respond-to(sm) {
    padding-left: 20px !important;
    padding-right: none !important;
  }
}

.centered-card-without-borders {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  margin-top: 0px !important;
  align-items: center !important;
}

.right-align-value {
  text-align: right;
  font-weight: 500;
}

.progress-col {
  width: 80%;
  align-content: center !important; /* Pack items around the center */
  text-align: left;
}

.progress {
  height: 1rem !important;
  margin: auto;
}

.right-align-col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
}

.no-margin {
  margin: 0px !important;
  max-width: 100% !important;
}

.left-align-col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.card-border {
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid black !important;
}

.eToken-table {
  th {
    background-color: $primary !important;
    color: white;
  }
  border: 1px solid black !important;
}

.single-eTokens {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.desktop {
  @include respond-to(sm) {
    display: none;
  }
}

.mobile {
  @include for-size(desktop-up) {
    display: none;
  }
  @include for-size(tablet-landscape-up) {
    display: none;
  }
  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.your-info-padding {
  h4 {
    color: $secondary;
  }

  p {
    padding-top: 10px;
    font-size: 16px;
  }

  padding-left: 20px;
  padding-top: 20px;
}

.your-info-buttons {
  text-align: end;
  margin-bottom: 20px;

  > button {
    margin: 5px;
  }
}

.right-padding {
  padding-right: 20px;
}

.interest-with-bg {
  background-color: $secondary-dark !important;
  color: white;
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: auto !important;

  p {
    @include for-size(phone-only) {
      padding-left: 20px !important;
    }
  }

  .mobile-padding {
    @include for-size(phone-only) {
      padding-left: 20px !important;
    }
  }

  .with-left-pad {
    @include for-size(desktop-up) {
      padding-left: 50px !important;
    }
    @include for-size(tablet-landscape-up) {
      padding-left: 50px !important;
    }
    @include for-size(tablet-portrait-up) {
      padding-left: 50px !important;
    }
  }

  h3 {
    color: white;
    @include for-size(phone-only) {
      padding-left: 20px !important;
    }
  }
}

.interest-title {
  color: white;
  margin-top: 30px !important;

  @include for-size(desktop-up) {
    padding-left: 50px !important;
  }
  @include for-size(tablet-landscape-up) {
    padding-left: 50px !important;
  }
  @include for-size(tablet-portrait-up) {
    padding-left: 50px !important;
  }
  @include for-size(phone-only) {
    padding-left: 20px !important;
  }
}

.apy-value {
  color: $green;
  font-weight: 600;
  @include for-size(phone-only) {
    padding-left: 20px !important;
  }
}

.apy-label {
  color: white;
  font-weight: 400;
}

.colored {
  color: $secondary !important;
}

.primary-colored {
  color: $primary !important;
}

.breakdown-item {
  h2 {
    color: $secondary-dark;
  }
}

.interest-params-desktop {
  .col-md-3 {
    width: 30%;
  }

  .col-md-1 {
    width: 3.33333%;
  }
}

.p-without-padding {
  p {
    margin: 0;
    padding: 0;
  }
}

.info-icon {
  font-size: 16px;
}

.with-padding {
  padding: 30px;
}

.xs-centered {
  @include respond-to(sm) {
    text-align: -webkit-center;
  }
}

.bar-step {
  position: absolute;
  z-index: 0;
  font-size: 12px;
}

.without-padding {
  padding: 0 !important;
}

.label-line {
  float: right;
  background: #000;
  height: 16px;
  width: 3px;
  background-color: $pink !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.table-rounded {
  border-radius: 20px !important;
}

.with-bkg {
  background-color: #e5f1ff !important;
  --bs-table-bg: #e5f1ff !important;
}

.tableFixHead {
  overflow: auto !important;
  max-height: 400px !important;
}

.tab-font {
  font-size: 18px;
  font-weight: 600;
}

.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
}

.activity-tabs {
  --bs-nav-tabs-border-width: 0 !important;
  --bs-nav-tabs-link-active-color: #025284 !important;
  --bs-nav-link-color: #b9cde2 !important;
}

.nav-tabs .nav-link.active {
  background-color: transparent !important;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse !important;
  width: 100% !important;
}
th,
td {
  padding: 8px 16px !important;
}
th {
  text-align: center !important;
  background: #e5f1ff !important;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

table tr:last-child th:first-child {
  border-top-left-radius: 20px;
}

table tr:last-child th:last-child {
  border-top-right-radius: 20px;
}
