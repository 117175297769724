@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

/* Style the counter cards */
.card {
  margin-top: 1.25rem;
  padding: 2px;
  background-color: $secondary-light;

  .a {
    color: #474b53 !important;
  }
}

.card-row {
  position: relative;
  width: auto;
  display: block;
  background-color: transparent;

  @media (min-width: 992px) {
    padding-left: 0;
  }
}

.primary-colored {
  color: $primary !important;
}

.card-inside {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  text-align: left;
  color: $primary;
  font-size: 16px;
  text-align: center;
}

.name-with-logo {
  color: $primary;
  padding: 20px;
}

.with-20-padding {
  padding-left: 1rem;
}

.with-40-padding {
  padding-left: 2rem;
}

.desktop {
  @include respond-to(sm) {
    display: none;
  }
}

.mobile {
  @include for-size(desktop-up) {
    display: none;
  }
  @include for-size(tablet-landscape-up) {
    display: none;
  }
  @include for-size(tablet-portrait-up) {
    display: none;
  }
}

.a {
  color: #474b53 !important;
}

.card-hover:hover {
  box-shadow: 0 1px 3px 0 rgb(0, 195, 255) !important;
  transition: all 0.2s ease;
}

.sl-circle {
  border: 10px solid $secondary-light !important;
  border-top: 10px solid $primary !important;
}

.table-header {
  text-align: center;
  font-size: 16px !important;
}

.margin-auto {
  margin: auto !important;
}

.padding-top-bottom-20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.sr-etk-name {
  font-size: 24px !important;
  font-weight: 600 !important;
  cursor: pointer;

  @include respond-to(sm) {
    font-size: 20px !important;
  }
}

.sr-etk-card {
  border: 1.5px solid !important;
  border-radius: 20px !important;
  border-color: #0055b8c2 !important;
  box-shadow: 0px 2px 4px rgba(15, 34, 58, 0.35) !important;
  // padding-top: 30px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  // background-color: white !important;
}

.sr-etk-number {
  font-size: 24px;
  font-weight: 600;
  margin: 0 !important;

  @include respond-to(sm) {
    font-size: 18px;
  }
}

.text-align-center {
  text-align: center;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.5em !important;
  font-size: 18px;
}
