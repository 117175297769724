@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.without-shadow {
  box-shadow: none !important;
}

.risk-button {
  margin: 0.2rem !important;
}

.card-detail-inside {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  text-align: center;
}

.no-margin {
  margin: 0px !important;
  max-width: 100% !important;
}

.cashflow-title {
  h4 {
    color: $secondary;
  }
  @include for-size(desktop-up) {
    padding-left: 20px !important;
  }
  @include for-size(tablet-landscape-up) {
    padding-left: 20px !important;
  }
  @include for-size(tablet-portrait-up) {
    padding-left: 20px !important;
  }
}

.centered {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.with-padding {
  padding: 20px;
  width: 100%;
  height: 90%;
}

.days-buttons-padding {
  @include for-size(desktop-up) {
    padding-right: 70px;
  }
  @include for-size(tablet-landscape-up) {
    padding-right: 70px;
  }
  @include for-size(tablet-portrait-up) {
    padding-right: 70px;
  }
}

.params {
  padding-left: 20px;
  padding-top: 20px;
  color: gray !important;
  font-size: 18px;
}

.params-value {
  color: $secondary-dark !important;
}

.widget-value {
  color: $secondary !important;
}

.params-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.risk-card-detail-inside {
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
  text-align: left;
  padding: "10px";

  hr {
    margin: 10px auto;
    width: 95%;
  }

  h4 {
    text-align: center;
  }

  span {
    font-weight: 600;
  }

  padding-left: 20px !important;
  padding-right: 20px !important;
}

.risk-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}

.risk-item-logo {
  flex: 0 0 35px;
  display: flex;
  justify-content: flex-start;
}

.risk-item-text {
  flex: 1;
  text-align: left;
  margin-top: 7.5px;
}

.risk-item-value {
  text-align: left;
  margin-top: 10px;
}

@include respond-to(sm) {
  .risk-item-container {
    flex-direction: column;
    justify-content: center;
  }

  .risk-item-logo {
    justify-content: center;
    margin-bottom: 10px;
  }

  .risk-item-text {
    text-align: center;
    margin-top: 0;
  }

  .risk-item-value {
    text-align: center;
  }
}
